<template>
    <v-chip
        class="person-label doc-version-label"
        small
        label
        dark
        @click.stop="produceGlobalEvent"
    >
        <v-icon>
            fas fa-clone
        </v-icon>
        <div>{{ text }}</div>
    </v-chip>
</template>

<script>
export default {
    name: "DocVersionChip",
    props: {
        id: String,
        text: String
    },
    methods: {
        produceGlobalEvent() {
            if (this.id)
                this.$eventBus.$emit('show-versions-information', { documentId: this.id });
        },
    }
}
</script>